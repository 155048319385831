<template>
  <div>
    <crud-form
      :mostrar-botao-info="true"
      :tabs-cabecalho="novasTabs"
      :titulo-pagina-tab="tituloPaginaTab"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
      :tab-selecionada.sync="tab"
      :forca-mostra-botao-salvar-continuar="true"
      @deleteEvent="remover"
      @closeDialogEvent="closeDialog"
      @saveEvent="saveEventHandler"
      @save-continue-event="saveContinueEvent"
      @mostrarInfo="updateHintStatus"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-dados-gerais'">
          <v-container class="py-0">
            <v-row>
              <v-col
                v-if="!idEmpresaCliente || idEmpresaCliente < 0"
                cols="12"
              >
                <empresa-cliente-auto-complete
                  v-model="empresaClienteModel"
                  :readonly="!!empresaClienteModelId || isEditting"
                  :clearable="!isEditting"
                  @click:clear="
                    limpaClientes();
                    limpaStatusEventos();
                  "
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                md="3"
              >
                <v-select
                  v-model="objEditar.idTipoEvento"
                  :rules="[required]"
                  :label="$t('EventosForm.tipoEvento')"
                  :items="tiposDeEventos"
                  item-value="id"
                  item-text="nomeTipoEvento"
                  required
                  prepend-icon="mdi-format-list-bulleted-type"
                  persistent-hint
                  :hint="showHint ? '${evento.idTipoEvento}' : undefined"
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                md="3"
              >
                <v-select
                  v-model="objEditar.idStatusEvento"
                  :rules="[required]"
                  :label="$t('EventosForm.statusEvento')"
                  :items="listaStatusEvento"
                  item-value="id"
                  item-text="nomeStatusEvento"
                  required
                  prepend-icon="mdi-format-list-bulleted-type"
                  persistent-hint
                  :hint="showHint ? '${evento.idStatusEvento}' : undefined"
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                md="3"
              >
                <v-text-field
                  v-model="objEditar.numeroConvidados"
                  :label="$t('EventosForm.numeroConvidados')"
                  prepend-icon="mdi-account-edit"
                  persistent-hint
                  :hint="showHint ? '${evento.numeroConvidados}' : undefined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                sm="3"
              >
                <v-menu
                  ref="menuDataEvento"
                  v-model="menuDataEvento"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataEventoFormatted"
                      :label="$t('EventosForm.dataEvento')"
                      persistent-hint
                      readonly
                      :rules="[required]"
                      required
                      :hint="showHint ? '${evento.dataEvento}' : undefined"
                      prepend-icon="mdi-calendar-text"
                      @blur="
                        objEditar.dataEvento = parseDate(dataEventoFormatted)
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="objEditar.dataEvento"
                    no-title
                    dark
                    @input="menuDataEvento = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                sm="3"
              >
                <v-menu
                  ref="menuHoraEvento"
                  v-model="menuHoraEvento"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="objEditar.horaEvento"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  persistent-hint
                  :hint="showHint ? '${evento.horaEvento}' : undefined"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="objEditar.horaEvento"
                      :label="$t('EventosForm.horaEvento')"
                      prepend-icon="mdi-clock"
                      readonly
                      persistent-hint
                      :hint="showHint ? '${evento.horaEvento}' : undefined"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-time-picker
                    v-if="menuHoraEvento"
                    v-model="objEditar.horaEvento"
                    full-width
                    format="24hr"
                    @click:minute="
                      $refs.menuHoraEvento.save(objEditar.horaEvento)
                    "
                  />
                </v-menu>
              </v-col>

              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="objEditar.local"
                  :label="$t('EventosForm.local')"
                  prepend-icon="mdi-account-edit"
                  persistent-hint
                  :hint="showHint ? '${evento.local}' : undefined"
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                sm="3"
              >
                <v-menu
                  ref="menuDataEventoSecundaria"
                  v-model="menuDataEventoSecundaria"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataEventoSecundariaFormatted"
                      :label="$t('EventosForm.dataEventoSecundaria')"
                      persistent-hint
                      readonly
                      :rules="[required]"
                      required
                      :hint="showHint ? '${evento.dataEventoSecundaria}' : undefined"
                      prepend-icon="mdi-calendar-text"
                      @blur="
                        objEditar.dataEventoSecundaria = parseDate(
                          dataEventoSecundariaFormatted
                        )
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="objEditar.dataEventoSecundaria"
                    no-title
                    dark
                    prepend-icon="mdi-calendar-text"
                    @input="menuDataEventoSecundaria = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                sm="3"
              >
                <v-menu
                  ref="menuHoraEventoSecundaria"
                  v-model="menuHoraEventoSecundaria"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="objEditar.horaEventoSecundaria"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :hint="showHint ? '${evento.horaEventoSecundaria}' : undefined"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="objEditar.horaEventoSecundaria"
                      :label="$t('EventosForm.horaEventoSecundaria')"
                      prepend-icon="mdi-clock"
                      readonly
                      persistent-hint
                      :hint="showHint ? '${evento.horaEventoSecundaria}' : undefined"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-time-picker
                    v-if="menuHoraEventoSecundaria"
                    v-model="objEditar.horaEventoSecundaria"
                    full-width
                    format="24hr"
                    @click:minute="
                      $refs.menuHoraEventoSecundaria.save(
                        objEditar.horaEventoSecundaria
                      )
                    "
                  />
                </v-menu>
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="objEditar.localSecundario"
                  :label="$t('EventosForm.localSecundario')"
                  prepend-icon="mdi-account-edit"
                  persistent-hint
                  :hint="showHint ? '${evento.localSecunario}' : undefined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                md="3"
              >
                <v-menu
                  ref="menuDataSaidaMaterial"
                  v-model="menuDataSaidaMaterial"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSaidaMaterialFormatted"
                      :label="$t('EventosForm.dataSaidaMaterial')"
                      persistent-hint
                      readonly
                      :rules="[required]"
                      required
                      :hint="showHint ? '${evento.dataSaidaMaterial}' : undefined"
                      prepend-icon="mdi-sort-calendar-descending"
                      @blur="
                        objEditar.dataSaidaMaterial = parseDate(
                          dataSaidaMaterialFormatted
                        )
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="objEditar.dataSaidaMaterial"
                    no-title
                    dark
                    @input="menuDataSaidaMaterial = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="6"
                md="3"
              >
                <v-menu
                  ref="menuDataRetornoMaterial"
                  v-model="menuDataRetornoMaterial"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataRetornoMaterialFormatted"
                      :label="$t('EventosForm.dataRetornoMaterial')"
                      persistent-hint
                      readonly
                      :rules="[required]"
                      required
                      :hint="showHint ? '${evento.dataRetornoMaterial}' : undefined"
                      prepend-icon="mdi-sort-calendar-ascending"
                      @blur="
                        objEditar.dataRetornoMaterial = parseDate(
                          dataRetornoMaterialFormatted
                        )
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="objEditar.dataRetornoMaterial"
                    no-title
                    dark
                    @input="menuDataRetornoMaterial = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="autoCompleteModel"
                  :items="autoCompleteItens"
                  :loading="autoCompleteLoading"
                  :search-input.sync="autoCompleteCampoBusca"
                  :filter="filterAc"
                  :label="$t('EventosForm.nomeCliente')"
                  hide-no-data
                  item-text="nome"
                  item-value="id"
                  :placeholder="$t('padrao_digite_para_buscar')"
                  prepend-icon="mdi-database-search"
                  return-object
                  clearable
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="autoCompletePagadorModel"
                  :items="autoCompletePagadorItens"
                  :loading="autoCompletePagadorLoading"
                  :search-input.sync="autoCompletePagadorCampoBusca"
                  :filter="filterPagadorAc"
                  :label="$t('EventosForm.nomeClientePagador')"
                  hide-no-data
                  item-text="nome"
                  item-value="id"
                  :placeholder="$t('padrao_digite_para_buscar')"
                  prepend-icon="mdi-database-search"
                  return-object
                  clearable
                />
              </v-col>
              <v-col
                v-if="empresaClienteModelId"
                cols="12"
              >
                <v-textarea
                  v-model="objEditar.observacao"
                  outlined
                  :label="$t('EventosForm.observacao')"
                  prepend-icon="mdi-account-edit"
                />
              </v-col>

              <v-alert
                v-model="alertValidacao"
                dismissible
                type="error"
                transition="scale-transition"
              >
                {{ error }}
              </v-alert>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- fim dados gerais -->
        <!-- inicio itens -->
        <v-tab-item
          v-if="isEditting"
          :value="'tab-itens'"
        >
          <evento-itens
            :id-evento="objEditar.id"
            :id-empresa-cliente="empresaClienteModelId"
          />
        </v-tab-item>
        <!-- fim itens -->
        <!-- inicio pagamentos -->
        <v-tab-item
          v-if="isEditting"
          :value="'tab-pagamentos'"
        >
          <evento-pagamentos :id-evento="objEditar.id" />
        </v-tab-item>
        <!-- fim pagamentos -->
        <!-- inicio anexos -->
        <v-tab-item
          v-if="isEditting"
          :value="'tab-anexos'"
        >
          <evento-anexos :id-evento="objEditar.id" />
        </v-tab-item>
        <!-- fim anexos -->
      </v-tabs-items>
      <span
        v-if="tab !== 'tab-dados-gerais'"
        slot="botoes"
      />
      <v-btn
        v-if="objEditar.id"
        slot="botoesAdicionaisAntesCancelar"
        color="blue"
        class="hidden-sm-and-down"
        :loading="estaGerandoPdf"
        @click="abrirModalGeracaoPdf()"
      >
        {{ $t("padrao_gerar_pdf") }}
        <v-icon right>
          mdi-pdf-box
        </v-icon>
      </v-btn>
    </crud-form>

    <!-- Começo do Diálogo -->
    <v-dialog
      v-model="dialogFlag"
      :width="dialogWidth"
      :fullscreen="$vuetify.breakpointsmAndDown"
    >
      <v-card>
        <v-toolbar
          id="toolbar-header"
          color="primary"
          class="ml-0"
        >
          <v-toolbar-title>
            <span
              class="subheading font-weight-bold mr-3 white--text"
              style="align-self: left;"
            >{{ $t("padrao_gerar_pdf") }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          :indeterminate="true"
          :active="estaGerandoPdf"
          color="info"
          height="3"
          class="mt-0"
        />

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-radio-group v-model="objRelatorio.visao">
                  <template v-slot:label>
                    <div>{{ $t("EventosForm.visaoRelatorio") }}</div>
                  </template>
                  <v-radio
                    value="C"
                    :label="$t('EventosForm.cliente')"
                  />
                  <v-radio
                    value="E"
                    :label="$t('EventosForm.expedicao')"
                  />
                  <v-radio
                    v-for="contrato of contratos"
                    :key="contrato.id"
                    :value="'CON' + contrato.id"
                    :label="'Contrato ' + contrato.title"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="dialogFlag = false"
          >
            {{ $t("padrao_cancelar") }}
            <v-icon dark>
              mdi mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            @click="gerarPdf"
          >
            {{ $t("padrao_gerar") }}
            <v-icon dark>
              mdi mdi-pdf-box
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Servico from "@/services/EventosService";
  import TipoEventoService from "../services/TipoEventoService";
  import ClientesService from "../services/ClientesService";
  import StatusEventoService from "../services/StatusEventoService";
  import EventoItens from "./EventoItens";
  import EventoPagamentos from "./EventoPagamentos";
  import EventoAnexos from "./EventoAnexos";
  import DateUtils from "../utils/DateUtils";
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete";
  import EmpresaClientesService from "@/services/EmpresaClientesService";
  import ContratoService from "@/services/ContratoService";
  import { mapState } from "vuex";
  import hint from '@/mixins/hint'

  export default {
    components: {
      EventoItens,
      EventoPagamentos,
      EventoAnexos,
      EmpresaClienteAutoComplete
    },
    mixins: [hint],
    data: () => ({
      empresaClienteModel: null,
      empresaClienteModelId: null,
      menuDataEvento: false,
      menuHoraEvento: false,
      menuDataEventoSecundaria: false,
      menuHoraEventoSecundaria: false,
      menuDataSaidaMaterial: false,
      menuDataRetornoMaterial: false,
      autoCompleteModel: null,
      autoCompleteItens: [],
      autoCompleteLoading: false,
      autoCompleteCampoBusca: "",
      autoCompletePagadorModel: null,
      autoCompletePagadorItens: [],
      autoCompletePagadorLoading: false,
      autoCompletePagadorCampoBusca: "",
      estaGerandoPdf: false,
      dialogFlag: false,
      dialogWidth: "350px",
      objPadrao: {
        idTipoEvento: null,
        idStatusEvento: null,
        dataEvento: null,
        horaEvento: null,
        dataEventoSecundaria: null,
        horaEventoSecundaria: null,
        dataSaidaMaterial: null,
        dataRetornoMaterial: null,
        local: "",
        localSecundario: "",
        observacao: "",
        id: 0,
        numeroConvidados: null
      },
      objEditar: {
        idTipoEvento: null,
        idStatusEvento: null,
        dataEvento: null,
        horaEvento: null,
        dataEventoSecundaria: null,
        horaEventoSecundaria: null,
        dataSaidaMaterial: null,
        dataRetornoMaterial: null,
        local: "",
        localSecundario: "",
        observacao: "",
        id: 0,
        numeroConvidados: null
      },
      objRelatorio: {
        visao: "C"
      },
      required: value => !!value || "Campo Obrigatório",
      isEditting: false,
      error: null,
      validForm: false,
      alertValidacao: false,
      mostraBotaoSalvarContinuar: false,
      tab: null,
      tabCabecalhos: [],
      tiposDeEventos: [],
      listaStatusEvento: [],
      contratos: null
    }),
    computed: {
      ...mapState(["idEmpresaCliente"]),
      dataEventoFormatted: function() {
        return DateUtils.formatDate(this.objEditar.dataEvento);
      },
      dataEventoSecundariaFormatted: function() {
        return DateUtils.formatDate(this.objEditar.dataEventoSecundaria);
      },
      dataSaidaMaterialFormatted: function() {
        return DateUtils.formatDate(this.objEditar.dataSaidaMaterial);
      },
      dataRetornoMaterialFormatted: function() {
        return DateUtils.formatDate(this.objEditar.dataRetornoMaterial);
      },
      tituloPaginaTab() {
        return this.$i18n.t("EventosForm.tituloPagina");
      },
      tabCabecalhosValendo() {
        var tabValendo = this.tabCabecalhos.filter(guia => {
          return guia.mostraCabecalho;
        });
        return tabValendo;
      },
      novasTabs() {
        let retorno = [
          {
            texto: this.$i18n.t("EventosForm.tab-dados-gerais") || "Dados Gerais",
            link: "#tab-dados-gerais",
            icone: "mdi-account",
            mostraCabecalho: true
          },
          {
            texto: this.$i18n.t("EventosForm.tab-itens") || "Itens",
            link: "#tab-itens",
            icone: "mdi-archive",
            mostraCabecalho: this.isEditting
          },
          {
            texto: this.$i18n.t("EventosForm.tab-pagamentos") || "Pagamentos",
            link: "#tab-pagamentos",
            icone: "mdi-cash-multiple",
            mostraCabecalho: this.isEditting
          },
          {
            texto: this.$i18n.t("EventosForm.tab-anexos") || "Anexos",
            link: "#tab-anexos",
            icone: "mdi-paperclip",
            mostraCabecalho: this.isEditting
          }
        ];

        retorno = retorno.filter(guia => {
          return guia.mostraCabecalho;
        });

        return retorno;
      }
    },
    watch: {
      autoCompleteCampoBusca(val) {
        if (!val) {
          return;
        }
        if (val.length < 3) {
          return;
        }
        this.buscaClientes(val);
      },
      autoCompletePagadorCampoBusca(val) {
        if (!val) {
          return;
        }
        if (val.length < 3) {
          return;
        }
        this.buscaClientesPagador(val);
      },
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null;
          this.objEditar.idEmpresaCliente = null;
          this.empresaClienteModelId = null;
        } else {
          this.objEditar.EmpresaCliente = val;
          this.objEditar.idEmpresaCliente = val.id;
          this.empresaClienteModelId = val.id;
          this.preencheTipoEventos();
          this.preencheStatusEventos();
        }
      }
    },
    created() {
      this.$root.$emit("alteraMostraPesquisa", false);

      let id = this.$route.params.id;
      if (id) {
        this.editar(id);
      } else {
        this.isEditting = false;
        this.objEditar = this.objPadrao;

        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then(res => {
            this.objEditar.EmpresaCliente = res.data;
            this.empresaClienteModel = res.data;
          });
        }
      }
    },
    methods: {
      limpaClientes() {
        this.autoCompleteItens = [];
        this.autoCompleteModel = null;
        this.autoCompletePagadorItens = [];
        this.autoCompletePagadorModel = null;
      },
      limpaStatusEvento() {
        this.listaStatusEvento = [];
        this.objEditar.idStatusEvento = null;
      },

      parseDate(date) {
        return DateUtils.parseDate(date);
      },

      buscaClientes(param) {
        this.autoCompleteLoading = true;
        ClientesService.buscaClientesPorParam(
          param,
          this.objEditar.idEmpresaCliente
        )
          .then(clientes => {
            this.autoCompleteItens = clientes.data;
          })
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "Erro na busca dos clientes " + error
            });
          })
          .finally(() => {
            this.autoCompleteLoading = false;
          });
      },

      buscaClientesPagador(param) {
        this.autoCompletePagadorLoading = true;
        ClientesService.buscaClientesPorParam(
          param,
          this.objEditar.idEmpresaCliente
        )
          .then(clientes => {
            this.autoCompletePagadorItens = clientes.data;
          })
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "Erro na busca dos clientes " + error
            });
          })
          .finally(() => {
            this.autoCompletePagadorLoading = false;
          });
      },

      preencheTipoEventos() {
        this.tiposDeEventos = [];
        TipoEventoService.findAll(null, this.objEditar.idEmpresaCliente).then(
          tiposDeEventos => {
            if (
              tiposDeEventos &&
              tiposDeEventos.data &&
              tiposDeEventos.data.rows
            ) {
              this.tiposDeEventos = tiposDeEventos.data.rows;
            }
          }
        );
      },

      preencheStatusEventos() {
        this.listaStatusEvento = [];
        StatusEventoService.findAll(null, this.objEditar.idEmpresaCliente).then(
          listaStatusEvento => {
            if (
              listaStatusEvento &&
              listaStatusEvento.data &&
              listaStatusEvento.data.rows
            ) {
              this.listaStatusEvento = listaStatusEvento.data.rows;
            }
          }
        );
      },
      traduzir(value) {
        try {
          return this.$t(value);
        } catch (error) {
          return value;
        }
      },
      remover() {
        const registro = this.objEditar;
        try {
          Servico.delete(registro).then(res => {
            this.$root.$emit("mostra_dialogo_info", {
              dialogoTitulo: this.$t("padrao_sucesso"),
              dialogoDescricao: this.$t("padrao_registro_removido_sucesso")
            });
            this.buscar();
          });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              this.$t("padrao_ocorreu_um_erro_ultima_operacao") + ":\n ->" + error
          });
        }
      },
      saveContinueEvent() {
        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente;
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar, true);
        } else {
          this.inserir(this.objEditar, true);
        }
      },
      closeDialog() {
        this.buscar();
      },
      saveEventHandler() {
        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente;
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar);
        } else {
          this.inserir(this.objEditar);
        }
      },
      filterAc(item, queryText, itemText) {
        return true;
      },
      filterPagadorAc(item, queryText, itemText) {
        return true;
      },
      atualizar(registro, continuar) {
        try {
          if (this.autoCompleteModel) {
            registro.idCliente = this.autoCompleteModel.id;
          }
          if (this.autoCompletePagadorModel) {
            registro.idClientePagador = this.autoCompletePagadorModel.id;
          }

          Servico.update(registro).then(res => {
            if (!res) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
              });
            } else if (res.error) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "" + res.error
              });
            } else {
              this.$root.$emit("mostra_dialogo_info", {
                dialogoTitulo: this.$t("padrao_sucesso"),
                dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
              });
              if (continuar) {
                this.editar(res.data.id);
              } else {
                this.buscar();
              }
            }
          });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              "Não foi possível concluir a atualização, tente mais tarde\n" +
              error
          });
        }
      },
      buscar() {
        this.$router.push("/eventos");
      },
      inserir(evento, continuar) {
        try {
          if (this.autoCompleteModel) {
            evento.idCliente = this.autoCompleteModel.id;
          }
          if (this.autoCompletePagadorModel) {
            evento.idClientePagador = this.autoCompletePagadorModel.id;
          }
          Servico.insert(evento).then(response => {
            if (!response) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
              });
            } else if (response.error) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "" + response.error
              });
            } else {
              this.$root.$emit("mostra_dialogo_info", {
                dialogoTitulo: this.$t("padrao_sucesso"),
                dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
              });
              if (continuar) {
                this.editar(response.data.id);
              } else {
                this.buscar();
              }
            }
          });
        } catch (error) {
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao:
              this.$t("padrao_ocorreu_um_erro_ultima_operacao") + ":\n ->" + error
          });
        }
      },
      editar(id) {
        Servico.findById({ id })
          .then(registro => {
            this.objEditar = registro.data;
            this.isEditting = true;

            this.empresaClienteModel = this.objEditar.EmpresaCliente;
            this.empresaClienteModelId = this.objEditar.idEmpresaCliente;
            if (this.objEditar.idCliente) {
              ClientesService.findById({ id: this.objEditar.idCliente }).then(
                cliente => {
                  if (cliente.id) {
                    this.autoCompleteModel = cliente;
                    this.autoCompleteItens[0] = cliente;
                    this.autoCompleteCampoBusca = cliente.nome;
                  } else if (cliente.data.id) {
                    this.autoCompleteModel = cliente.data;
                    this.autoCompleteItens[0] = cliente.data;
                    this.autoCompleteCampoBusca = cliente.data.nome;
                  }
                }
              );
            }
            if (this.objEditar.idClientePagador) {
              ClientesService.findById({
                id: this.objEditar.idClientePagador
              }).then(cliente => {
                if (cliente.id) {
                  this.autoCompletePagadorModel = cliente;
                  this.autoCompletePagadorItens[0] = cliente;
                  this.autoCompletePagadorCampoBusca = cliente.nome;
                } else if (cliente.data.id) {
                  this.autoCompletePagadorModel = cliente.data;
                  this.autoCompletePagadorItens[0] = cliente.data;
                  this.autoCompletePagadorCampoBusca = cliente.data.nome;
                }
              });
            }
          })
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao:
                this.$t("padrao_ocorreu_um_erro_ultima_operacao") +
                ":\n ->" +
                error
            });
            this.isEditting = false;
          });
      },
      async abrirModalGeracaoPdf() {
        if (!this.contratos) {
          await this.preencheContratos();
        }
        this.dialogFlag = true;
      },
      async preencheContratos() {
        const contratosPage = await ContratoService.findAll({
          idTipoEvento: this.objEditar.idTipoEvento
        });
        if (contratosPage && contratosPage.data && contratosPage.data.rows) {
          this.contratos = contratosPage.data.rows;
        }
      },
      gerarPdf() {
        this.estaGerandoPdf = true;
        this.objRelatorio.idEvento = this.objEditar.id;
        Servico.gerarPdf(this.objRelatorio)
          .then(() => {})
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao:
                this.$i18n.t("padrao_nao_foi_possivel_gerar_pdf") +
                ":\n ->" +
                error
            });
          })
          .finally(() => {
            this.estaGerandoPdf = false;
            this.dialogFlag = false;
          });
      }
    }
  };
</script>
